/*---------------------------------*/
/* ------ Configuración Endpoints Services ------*/
export const API_LICENCIAMIENTO = 'https://qacorelicence.gse.com.co';
export const API_SEGURIDAD = 'https://qacoresecurity.gse.com.co/security';
export const API_EXTERNAL_SOURCE = 'https://jkcge88asd.execute-api.us-east-2.amazonaws.com/qa';
export const API_PAYMENTSERVICESVINKEL = process.env.NODE_ENV === 'production'
  ? 'https://paymentservice.vinkel.co/v1/'
  : 'https://paymentservice.vinkel.co/v1/';
export const EPAYCOPAYMENTSSERVICES = process.env.NODE_ENV === 'production'
  ? 'https://qa-payment.gse.com.co/payment'
  : 'https://qa-payment.gse.com.co/payment';
export const API_AUTHENTICATION = process.env.NODE_ENV === 'production'
  ? 'https://jkcge88asd.execute-api.us-east-2.amazonaws.com/qa/authentication'
  : 'https://jkcge88asd.execute-api.us-east-2.amazonaws.com/qa/authentication';
export const API_IBUHOO_EMAIL = 'https://jkcge88asd.execute-api.us-east-2.amazonaws.com/qa';
/**
 * URL para Socket
 */
export const API_SOCKET_ENDPOINT = 'https://qacoresecurity.gse.com.co/socket';
/*---------------------------------*/
/* ------ Configuración Sites ------*/
export const URL_WEB_COLFACTURA = 'https://qaweb.colfactura.com/';
export const URL_WEB_COLNOMINA = 'https://qanomina.colfactura.com/';
export const URL_WEB_FIRMAYA = 'https://qaweb.firmaya.co/';
export const URL_WEB_FIRMAMAIL = 'https://qaweb.colfactura.com/';
export const URL_WEB_SUPERGUARDA = 'qa-market.superguarda.com.co/';
export const URL_WEB_IBUHOO = '/';
export const URL_SECURITY = 'https://qasecurity-licence.gse.com.co/';
export const URL_SECURITY_LETMICERT = 'https://qasecurity-licence.letmi.app/';
export const URL_CONTACT_COLNOMINA = 'https://forms.gle/JFwN3uWqjGzX5EQaA';

/* ------ Configuración Respuestas Servicio ------*/
/**
 * Respuestas de servicio
 */
export const DEFAULTRESPONSE = {
  noResponseFromApi: 'No hay respuesta por parte del servidor. Por favor intente más tarde.',
  errorInRequest: 'El servidor no ha podido interpretar la solicitud. Por favor contacte al administrador.',
  errorRegisterPurchaseOrder: 'Se ha presentado un error al registrar tu orden de compra, Por favor intenta nuevamente.', // error en licenciamiento
  errorRegisterPaymentVinkel: 'Se ha presentado un error al proceder a realizar el pago en línea, Por favor intenta nuevamente.', // error en ecommerce vinkel
  errorDownloadFile: 'Se ha presentado un error al intentar descargar el archivo.',
};

/* ------ Configuración Paginacion ------*/
/**
 * Configuración Paginacion numero de registros
 */
export const NUM_REGISTERS_PAGING = 30;

/**
 * token prefix eg. 'Bearer ' or 'Token '
 */
export const TOKEN_PREFIX = 'Bearer ';

/**
 * Llave de encripción
 */
export const ENCRYPTION_KEY = [
  227,
  144,
  75,
  59,
  108,
  168,
  38,
  75,
  193,
  195,
  63,
  125,
  144,
  17,
  12,
  148,
  217,
  49,
  188,
  190,
  52,
  189,
  68,
  134,
  106,
  170,
  255,
  88,
  27,
  183,
  249,
  88,
];

/**
 * Iv de encripción
 */
export const ENCRYPTION_IV = [
  58,
  237,
  223,
  159,
  226,
  61,
  73,
  88,
  156,
  25,
  99,
  11,
  144,
  81,
  32,
  101,
];

/**
 * Variable booleana encriptar contenido de localstorage
 */
export const LOCAL_STORAGE_ENCRYPT = true;

/**
 * API Key Google Maps
 */
export const API_KEY_GOOGLE_MAPS = process.env.NODE_ENV === 'production'
  ? 'AIzaSyDXv5FNyZvEOqj9LmKM-K-sGPlTCY9AGvQ'
  : 'AIzaSyDXv5FNyZvEOqj9LmKM-K-sGPlTCY9AGvQ';

/**
 * Validación de pago epayco
 */
export const VALIDATE_PAYMENT_EPAYCO = false;

/**
 * Segundos para validación de pago
 */
export const SECONDS_VALIDATE_PAYMENT = '30';

/**
 * URL Reporte Gestión Servicio
 */
export const URL_REPORT_GESTION_SERVICIO = 'https://consultaLicense:D-HsrVPtI]jd_UDhp|Z7yYIm3)wd)e@rsgsecontrol.gse.com.co:60443/Reports/report/SuiteGSE%20License/Mesa%20Servicio/Gesti%C3%B3n%20Servicio';

/**
 * URL Reporte Soporte Cliente
 */
export const URL_REPORT_SOPORTE_CLIENTE = 'http://consultaLicense:D-HsrVPtI]jd_UDhp|Z7yYIm3)wd)e@192.168.150.7/Reports/report/SuiteGSE%20License/Mesa%20Servicio/Soporte%20por%20Cliente';
/**
 * URL Reporte Códigos Promocionales
 */
export const URL_REPORT_CODIGOS_PROMOCIONALES = 'http://consultaLicense:D-HsrVPtI]jd_UDhp|Z7yYIm3)wd)e@192.168.150.7/ReportServer/Pages/ReportViewer.aspx?%2fSuiteGSE+License%2fOperaci%c3%b3n%2fUso+C%c3%b3digos+Promocionales&rc:Toolbar=true&rs:ClearSession=True';
/**
 * URL Reporte Consumo
 */
export const URL_REPORT_CONSUMO = 'http://consultaLicense:D-HsrVPtI]jd_UDhp|Z7yYIm3)wd)e@192.168.150.7/ReportServer/Pages/ReportViewer.aspx?%2fSuiteGSE+License%2fOperación%2fConsumo+de+Bolsas&rs:Command=Render';

/**
 * URL Reporte Consumo personalizado
 */
export const URL_REPORT_CONSUMO_CUSTOM = 'http://consultaLicense:D-HsrVPtI%5Djd_UDhp%7CZ7yYIm3)wd)e@192.168.150.7//ReportServer/Pages/ReportViewer.aspx?/SuiteGSE%20License/WebApp/ConsumoServiciosURL&pfechadesde={{pfechadesde}}&pfechahasta={{pfechahasta}}&ptipodocumento={{ptipodocumento}}&pnumerodocumento={{pnumerodocumento}}&pnombreproducto={{pnombreproducto}}&pcaracteristicaservicio={{pcaracteristicaservicio}}&psupervisado={{psupervisado}}&rc:Toolbar=true&rs:ClearSession=True';

export const LICENSE_PRIVILEGE_FULL_ACCESS = 'LICENSE_FULL_ACCESS';
